/* Chatbot.css */

.chatbot-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 90vh;
  }
  
  .chat-window {
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: default;
    overflow-wrap: break-word;
    width: 100%;
    display: flex;
    flex-direction: column;
    
  }
  
  .message {
    padding: 5px;
    border-radius: 4px;
    margin: 5px 0;
    text-align: left;
    border: 1px rgb(161, 161, 161) solid;
    overflow-x: wrap;

  }
  .message-role {
    /* color: black; */
  }
  
  .user-message {
    /* background-color: #e0e0e0; */
  }
  
  .ai-message {
    background-color: #dddddd;
    /* color: #fff; */
  }
  
  .message-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 4px;

  }
  
  .send-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  